import { template as template_570d8cdf944a43c7b648e9a1798974c4 } from "@ember/template-compiler";
const FKControlMenuContainer = template_570d8cdf944a43c7b648e9a1798974c4(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
