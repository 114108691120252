define("discourse/plugins/discourse-cartalk/discourse/components/composer-make-model", ["exports", "@ember/component", "@ember/object/computed", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _computed, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.site.mobileView}}
    {{combo-box
      name="cartalk-make"
      value=this.makeSelected
      content=this.makesForSelect
      optionValuePath="content.id"
      optionLabelPath="content.name"
      none="cartalk.make"
    }}
    {{combo-box
      name="cartalk-make"
      value=this.modelSelected
      content=this.modelsForSelect
      optionValuePath="content.id"
      optionLabelPath="content.name"
      none="cartalk.model"
      options=(hash disabled=this.modelsDisabled)
    }}
  {{else}}
    {{combo-box
      name="cartalk-make"
      value=this.makeSelected
      content=this.makesForSelect
      optionValuePath="content.id"
      optionLabelPath="content.name"
      none="cartalk.choose_make"
    }}
    {{combo-box
      name="cartalk-make"
      value=this.modelSelected
      content=this.modelsForSelect
      optionValuePath="content.id"
      optionLabelPath="content.name"
      none="cartalk.choose_model"
      options=(hash disabled=this.modelsDisabled)
    }}
  {{/if}}
  */
  {
    "id": "RZN4GlI1",
    "block": "[[[41,[30,0,[\"site\",\"mobileView\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"name\",\"value\",\"content\",\"optionValuePath\",\"optionLabelPath\",\"none\"],[\"cartalk-make\",[30,0,[\"makeSelected\"]],[30,0,[\"makesForSelect\"]],\"content.id\",\"content.name\",\"cartalk.make\"]]]],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"name\",\"value\",\"content\",\"optionValuePath\",\"optionLabelPath\",\"none\",\"options\"],[\"cartalk-make\",[30,0,[\"modelSelected\"]],[30,0,[\"modelsForSelect\"]],\"content.id\",\"content.name\",\"cartalk.model\",[28,[37,2],null,[[\"disabled\"],[[30,0,[\"modelsDisabled\"]]]]]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"name\",\"value\",\"content\",\"optionValuePath\",\"optionLabelPath\",\"none\"],[\"cartalk-make\",[30,0,[\"makeSelected\"]],[30,0,[\"makesForSelect\"]],\"content.id\",\"content.name\",\"cartalk.choose_make\"]]]],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"name\",\"value\",\"content\",\"optionValuePath\",\"optionLabelPath\",\"none\",\"options\"],[\"cartalk-make\",[30,0,[\"modelSelected\"]],[30,0,[\"modelsForSelect\"]],\"content.id\",\"content.name\",\"cartalk.choose_model\",[28,[37,2],null,[[\"disabled\"],[[30,0,[\"modelsDisabled\"]]]]]]]]],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"combo-box\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-cartalk/discourse/components/composer-make-model.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    title: "Tag topic with vehicle make and model",
    classNameBindings: [":cartalk-composer-make-model"],
    layoutName: "templates/components/composer-make-model",
    makesGroup: null,
    modelsGroups: null,
    makeSelected: null,
    modelSelected: null,
    prevMakeSelected: null,
    prevModelSelected: null,
    modelsDisabled: (0, _computed.empty)("modelsForSelect"),
    init() {
      this._super(...arguments);
      this.set("modelsGroups", {});
    },
    makesForSelect(makesGroup) {
      if (makesGroup && makesGroup.get("tag_names")) {
        return makesGroup.get("tag_names").map(tagName => {
          return {
            id: tagName,
            name: tagName
          };
        });
      } else {
        return [];
      }
    },
    modelsForSelect(makeSelected) {
      if (makeSelected && this.modelsGroups[makeSelected]) {
        return this.modelsGroups[makeSelected].tag_names.map(tagName => {
          return {
            id: tagName,
            name: tagName
          };
        });
      } else {
        return [];
      }
    },
    _addTagsToTopic() {
      if (this.composer) {
        // keep other tags that were added, replacing make and model tags
        let topicTags = this.composer.tags;
        if (topicTags === undefined) {
          topicTags = [];
        }
        topicTags = topicTags.reject(t => {
          return t === this.prevMakeSelected || t === this.prevModelSelected;
        });
        topicTags.pushObject(this.makeSelected);
        topicTags.pushObject(this.modelSelected);
        this.composer.set("tags", topicTags.reject(t => !t || t.length === 0).uniq());
      }
      this.setProperties({
        prevMakeSelected: this.makeSelected,
        prevModelSelected: this.modelSelected
      });
    },
    setupComboBoxes() {
      this.store.findAll("cartalk/makeModelTagGroup").then(groups => {
        this.set("makesGroup", groups.find(item => item.name === "Makes"));
        groups.forEach(item => {
          if (item.name.indexOf("Makes-") === 0) {
            this.modelsGroups[item.name.substring(6).toLowerCase()] = item;
          }
        });
      });
    }
  }, [["method", "makesForSelect", [(0, _decorators.default)("makesGroup")]], ["method", "modelsForSelect", [(0, _decorators.default)("makeSelected")]], ["method", "_addTagsToTopic", [(0, _decorators.observes)("modelSelected", "makeSelected")]], ["method", "setupComboBoxes", [(0, _decorators.on)("init")]]])));
});