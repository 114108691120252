define("discourse/plugins/discourse-cartalk/discourse/components/cartalk-make-model", ["exports", "@ember/component", "@ember/object/computed", "jquery", "discourse/lib/url", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _computed, _jquery, _url, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.site.mobileView}}
    {{d-button translatedLabel="Make & Model" action=(action "togglePopup")}}
  {{else}}
    <a href {{action "togglePopup"}}>Make & Model</a>
  {{/if}}
  
  {{#if this.showPopup}}
    <div class="cartalk-mm-popup">
      {{combo-box
        name="cartalk-make"
        value=this.makeSelected
        content=this.makesForSelect
        optionValuePath="content.id"
        optionLabelPath="content.name"
        none="cartalk.choose_make"
      }}
  
      <br />
      <br />
  
      {{combo-box
        name="cartalk-make"
        value=this.modelSelected
        content=this.modelsForSelect
        optionValuePath="content.id"
        optionLabelPath="content.name"
        none="cartalk.choose_model"
        options=(hash disabled=this.modelsEmpty)
      }}
    </div>
  {{/if}}
  */
  {
    "id": "GCoaJggY",
    "block": "[[[41,[30,0,[\"site\",\"mobileView\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"translatedLabel\",\"action\"],[\"Make & Model\",[28,[37,2],[[30,0],\"togglePopup\"],null]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,3],[24,6,\"\"],[4,[38,2],[[30,0],\"togglePopup\"],null],[12],[1,\"Make & Model\"],[13],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,0,[\"showPopup\"]],[[[1,\"  \"],[10,0],[14,0,\"cartalk-mm-popup\"],[12],[1,\"\\n    \"],[1,[28,[35,5],null,[[\"name\",\"value\",\"content\",\"optionValuePath\",\"optionLabelPath\",\"none\"],[\"cartalk-make\",[30,0,[\"makeSelected\"]],[30,0,[\"makesForSelect\"]],\"content.id\",\"content.name\",\"cartalk.choose_make\"]]]],[1,\"\\n\\n    \"],[10,\"br\"],[12],[13],[1,\"\\n    \"],[10,\"br\"],[12],[13],[1,\"\\n\\n    \"],[1,[28,[35,5],null,[[\"name\",\"value\",\"content\",\"optionValuePath\",\"optionLabelPath\",\"none\",\"options\"],[\"cartalk-make\",[30,0,[\"modelSelected\"]],[30,0,[\"modelsForSelect\"]],\"content.id\",\"content.name\",\"cartalk.choose_model\",[28,[37,7],null,[[\"disabled\"],[[30,0,[\"modelsEmpty\"]]]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"d-button\",\"action\",\"a\",\"div\",\"combo-box\",\"br\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-cartalk/discourse/components/cartalk-make-model.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    title: "Filter by vehicle make and model",
    classNameBindings: [":cartalk-mm-nav-item", "showPopup:active"],
    layoutName: "templates/components/cartalk-make-model",
    readyToShow: false,
    showPopup: false,
    makesGroup: null,
    modelsGroups: null,
    makeSelected: null,
    modelSelected: null,
    modelsEmpty: (0, _computed.empty)("modelsForSelect"),
    makesForSelect(makesGroup) {
      return makesGroup.get("tag_names").map(tagName => {
        return {
          id: tagName,
          name: tagName
        };
      });
    },
    modelsForSelect(makeSelected) {
      if (makeSelected && this.modelsGroups[makeSelected]) {
        return this.modelsGroups[makeSelected].tag_names.map(tagName => {
          return {
            id: tagName,
            name: tagName
          };
        });
      } else {
        return [];
      }
    },
    _filterByMake() {
      if (this.makeSelected && this.modelsEmpty) {
        // Some makes have no models, like Hummer. Show filtered results immediately.
        _url.default.routeTo(`/tag/${this.makeSelected}`);
      }
    },
    _filterByModel() {
      if (this.modelSelected) {
        _url.default.routeTo(`/tag/${this.modelSelected}`);
      }
    },
    init() {
      this._super(...arguments);
      this.set("modelsGroups", {});
      this.set("readyToShow", false);
      this.store.findAll("cartalk/makeModelTagGroup").then(groups => {
        this.set("makesGroup", groups.find(item => item.name === "Makes"));
        groups.forEach(item => {
          if (item.name.indexOf("Makes-") === 0) {
            this.modelsGroups[item.name.substring(6).toLowerCase()] = item;
          }
        });
        this.set("readyToShow", true);
      });
    },
    didInsertElement() {
      this._super(...arguments);
      const $html = (0, _jquery.default)("html");
      $html.on("mousedown.outside-cartalk-mm", e => {
        if ((0, _jquery.default)(this.element).has(e.target).length !== 0) {
          return;
        }
        this.set("showPopup", false);
        return true;
      });
    },
    willDestroyElement() {
      this._super(...arguments);
      this.set("showPopup", false);
      (0, _jquery.default)("html").off("mousedown.outside-cartalk-mm");
    },
    actions: {
      togglePopup() {
        if (this.readyToShow) {
          this.toggleProperty("showPopup");
        }
      }
    }
  }, [["method", "makesForSelect", [(0, _decorators.default)("makesGroup")]], ["method", "modelsForSelect", [(0, _decorators.default)("makeSelected")]], ["method", "_filterByMake", [(0, _decorators.observes)("modelsForSelect")]], ["method", "_filterByModel", [(0, _decorators.observes)("modelSelected")]]])));
});