import { template as template_98f78b973a3b4e25ba4b5b976fbc8642 } from "@ember/template-compiler";
import concatClass from "discourse/helpers/concat-class";
const FKSection = template_98f78b973a3b4e25ba4b5b976fbc8642(`
  <div class={{concatClass "form-kit__section" @class}} ...attributes>
    {{#if @title}}
      <h2 class="form-kit__section-title">{{@title}}</h2>
    {{/if}}

    {{#if @subtitle}}
      <span class="form-kit__section-subtitle">{{@subtitle}}</span>
    {{/if}}

    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKSection;
