import { template as template_0985d4f987864c9bbcec2b34c6b88e82 } from "@ember/template-compiler";
const SidebarSectionMessage = template_0985d4f987864c9bbcec2b34c6b88e82(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
