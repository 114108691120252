define("discourse/plugins/discourse-cartalk/discourse/components/discourse-cartalk", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @model.showCarTalkHouseAds1}}
    {{cartalk-house-ad
      placement="post-bottom"
      setNum="1"
      postNumber=@model.post_number
      topicId=@model.topic_id
    }}
  {{/if}}
  {{#if @model.showCarTalkHouseAds2}}
    {{cartalk-house-ad
      placement="post-bottom"
      setNum="2"
      postNumber=@model.post_number
      topicId=@model.topic_id
    }}
  {{/if}}
  */
  {
    "id": "40Q2BDWZ",
    "block": "[[[41,[30,1,[\"showCarTalkHouseAds1\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"placement\",\"setNum\",\"postNumber\",\"topicId\"],[\"post-bottom\",\"1\",[30,1,[\"post_number\"]],[30,1,[\"topic_id\"]]]]]],[1,\"\\n\"]],[]],null],[41,[30,1,[\"showCarTalkHouseAds2\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"placement\",\"setNum\",\"postNumber\",\"topicId\"],[\"post-bottom\",\"2\",[30,1,[\"post_number\"]],[30,1,[\"topic_id\"]]]]]],[1,\"\\n\"]],[]],null]],[\"@model\"],false,[\"if\",\"cartalk-house-ad\"]]",
    "moduleName": "discourse/plugins/discourse-cartalk/discourse/components/discourse-cartalk.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)(undefined, "discourse-cartalk"));
});