define("discourse/plugins/discourse-cartalk/discourse/api-initializers/apply-cartalk", ["exports", "discourse/lib/api", "discourse/widgets/render-glimmer", "discourse/plugins/discourse-cartalk/discourse/components/discourse-cartalk", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _api, _renderGlimmer, _discourseCartalk, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CartalkWrapper = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <DiscourseCartalk @model={{@data.post}} />
  
  */
  {
    "id": "uecJqb1n",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@model\"],[[30,1,[\"post\"]]]],null],[1,\"\\n\"]],[\"@data\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-cartalk/discourse/api-initializers/apply-cartalk.js",
    "scope": () => [_discourseCartalk.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "apply-cartalk:CartalkWrapper"));
  var _default = _exports.default = (0, _api.apiInitializer)("0.8", api => {
    const siteSettings = api.container.lookup("service:site-settings");
    api.modifyClass("model:post", Superclass => class extends Superclass {
      get showCarTalkHouseAds1() {
        const n = siteSettings.cartalk_ads_1_after_nth_post;
        return n === this.post_number; // only show once per topic as per their spec
      }
      get showCarTalkHouseAds2() {
        const n = siteSettings.cartalk_ads_2_after_nth_post;
        return n === this.post_number; // only show once per topic as per their spec
      }
    });
    api.decorateWidget("post:after", dec => {
      const post = dec.widget.findAncestorModel();
      return new _renderGlimmer.default(dec.widget, "div.widget-connector", CartalkWrapper, {
        post
      });
    });
  });
});