import { template as template_6ca5f97723b246f7af9fb961db89c5ad } from "@ember/template-compiler";
import icon from "discourse/helpers/d-icon";
import getURL from "discourse/lib/get-url";
import { i18n } from "discourse-i18n";
const BackToForum = template_6ca5f97723b246f7af9fb961db89c5ad(`
  <a href={{getURL "/"}} class="sidebar-sections__back-to-forum">
    {{icon "arrow-left"}}

    <span>{{i18n "sidebar.back_to_forum"}}</span>
  </a>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackToForum;
